// creating the entity adapter
import { Campaign } from '@models';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
export interface CampaignState extends EntityState<Campaign> {
  isLoading: boolean;
}
export const campaignAdapter = createEntityAdapter<Campaign>();
export const initialState: CampaignState = campaignAdapter.getInitialState({
  isLoading: false,
});
