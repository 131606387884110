import { Campaign, DateRangePickerModel } from '@models';
import { Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const campaignActions = createActionGroup({
  source: 'Campaign page',
  events: {
    Update: props<{
      data: Partial<Campaign> & { image?: File };
      id: Campaign['id'];
      ref?: string;
      message?: {
        success?: string;
        error?: string;
      };
    }>(),
    'Update  Success': props<{ data: Update<Campaign> }>(),
    Delete: props<{
      id: Campaign['id'];
    }>(),
    'Delete  Success': props<{ id: Campaign['id'] }>(),
    archive: props<{
      id: Campaign['id'];
    }>(),
    'archive  Success': props<{ id: Campaign['id'] }>(),
    send: props<{
      data: DateRangePickerModel.DateFilter & {
        source: string[];
        campaignIds: Campaign['id'][];
      };
      ref?: string;
    }>(),
    create: props<{ data: Campaign & { image?: File }; ref?: string }>(),
    'Create  Success': props<{ campaign: Campaign }>(),
    'fetch ': emptyProps(),
    'fetch  Success': props<{ campaigns: Campaign[] }>(),
    'fetch  Failed': props<{ error: string }>(),
    'update loading': props<{ isLoading: boolean }>(),
  },
});
