import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CampaignState, campaignAdapter } from './campaign.state';
import { Campaign } from '@models';

export const CAMPAIGN_STATE_NAME = 'campaign';
const selectCampaignState =
  createFeatureSelector<CampaignState>(CAMPAIGN_STATE_NAME);
const campaignSelector = campaignAdapter.getSelectors();
const selectCampaigns = createSelector(
  selectCampaignState,
  campaignSelector.selectAll
);
const selectCampaignEntities = createSelector(
  selectCampaignState,
  campaignSelector.selectEntities
);
const selectCampaignById = (id: Campaign['id']) =>
  createSelector(selectCampaignEntities, campaigns =>
    campaigns ? campaigns[id] : undefined
  );
const selectCampaignIsLoading = createSelector(
  selectCampaignState,
  state => state.isLoading
);
const selectCampaignsByIds = (ids: Campaign['id'][]) =>
  createSelector(selectCampaigns, campaigns =>
    campaigns.filter(campaign => ids.includes(campaign.id))
  );
export default {
  selectCampaignById,
  selectCampaignEntities,
  selectCampaignIsLoading,
  selectCampaigns,
  selectCampaignsByIds,
};
